<template>
  <base-layout title="My Profile">
    <ion-list>
      <ion-item lines="none">
        <ion-img class="profile-image" :src="profile_picture"></ion-img>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Name: </ion-note>
          {{ name }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Username: </ion-note>
          {{ username }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Class: </ion-note>
          {{ class_name }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Roll No: </ion-note>
          {{ roll }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Email: </ion-note>
          {{ email }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Phone: </ion-note>
          {{ phone }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Phone 2: </ion-note>
          {{ phone_alternate }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>D.O.B: </ion-note>
          {{ new Date(dob).toDateString() }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Gender: </ion-note>
          {{ gender }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Blood Group: </ion-note>
          {{ blood }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Address: </ion-note>
          {{ address }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Pincode: </ion-note>
          {{ pincode }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Fathers name: </ion-note>
          {{ fathers_name }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Mothers name: </ion-note>
          {{ mothers_name }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>PAN No: </ion-note>
          {{ pan_no }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Passport No: </ion-note>
          {{ passport_no }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>Voter ID: </ion-note>
          {{ voter_id }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>AADHAR: </ion-note>
          {{ aadhar_no }}
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-note>DL: </ion-note>
          {{ dl_no }}
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-button
      expand="full"
      class="ion-margin-vertical link"
      :router-link="{ name: 'profile.update' }"
    >
      Edit
    </ion-button>
  </base-layout>
</template>

<script>
import {
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonButton,
  IonImg,
} from "@ionic/vue";

import BaseUrl from "../../../apis/base_url";

export default {
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonNote,
    IonButton,
    IonImg,
  },

  data() {
    return {
      logo: "assets/img/logo.png",
      user_id: null,
      username: null,
      name: null,
      class_name: null,
      roll: null,
      email: null,
      phone: null,
      phone_alternate: null,
      dob: null,
      gender: null,
      blood: null,
      address: null,
      pincode: null,
      fathers_name: null,
      mothers_name: null,
      pan_no: null,
      passport_no: null,
      voter_id: null,
      aadhar_no: null,
      dl_no: null,
      password: null,
    };
  },

  computed: {
    profile_picture() {
      return this.user.profile_picture
        ? BaseUrl + "storage/" + this.user.profile_picture.url
        : this.logo;
    },
    genders() {
      return this.$store.getters["attribute/genders"];
    },
    bloodGroups() {
      return this.$store.getters["attribute/bloodGroups"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
  },

  mounted() {
    const user = this.user;
    this.user_id = user.id;
    this.username = user.username;
    this.name = user.user_detail.name;
    this.class_name = `${user.student.section_standard.standard.name} - ${user.student.section_standard.section.name}`;
    this.roll = user.student.roll_no;
    this.email = user.email;
    this.phone = user.user_detail.phone;
    this.phone_alternate = user.user_detail.phone_alternate;
    this.dob = user.user_detail.dob;
    this.address = user.user_detail.address;
    this.pincode = user.user_detail.pincode;
    this.fathers_name = user.user_detail.fathers_name;
    this.mothers_name = user.user_detail.mothers_name;
    this.pan_no = user.user_detail.pan_no;
    this.passport_no = user.user_detail.passport_no;
    this.voter_id = user.user_detail.voter_id;
    this.aadhar_no = user.user_detail.aadhar_no;
    this.dl_no = user.user_detail.dl_no;
  },

  methods: {
    async fetchGenders() {
      await this.$store.dispatch("attribute/genders");
      const selectedGender = this.genders.find(
        (gender) => gender.id == this.user.user_detail.gender_id
      );
      this.gender = selectedGender.name;
    },

    async fetchBloodGroups() {
      await this.$store.dispatch("attribute/bloodGroups");
      const bloodGroup = this.bloodGroups.find(
        (bloodGroup) => bloodGroup.id == this.user.user_detail.blood_group_id
      );
      this.blood = bloodGroup.name;
    },
  },

  created() {
    this.fetchBloodGroups();
    this.fetchGenders();
  },
};
</script>

<style scoped></style>